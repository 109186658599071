import React from "react"

import "./about.scss"

export default function Home() {
  return (
    <div>
      <h2>About us</h2>
    </div>
  )
}
